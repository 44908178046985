import { analytics } from '@play-co/gcinstant';

export function trackScreenOpen(props: { id: string } & Record<string, any>) {
    analytics.pushEvent('ScreenOpen', props);
}

export function trackScreenClose(props: { id: string } & Record<string, any>) {
    analytics.pushEvent('ScreenClose', props);
}

// produce and build flows can skip using gems
export function sendSkipEvent(props: { feature: 'build' | 'craft' | 'train'; gemCost: number }) {
    analytics.pushEvent('Skip', props);
}

export function trackPushRequest(props: { result: 'accept' | 'deny' }) {
    analytics.pushEvent('PushRequest', props);
}
