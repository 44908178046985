import { PlatformWeb } from '@play-co/gcinstant';
import { Container, NineSlicePlane } from 'pixi.js';

import app from '../../../index.entry';
import { SizeType } from '../../../lib/defs/types';
import { uiAlignRight } from '../../../lib/pixi/uiTools';
import { firstTapPromise } from '../../../lib/util/events';
import { InstantGame } from '../../../plugins/instantGames/InstantGame';
import { LayoutScreen3 } from '../../lib/screens/LayoutScreen3';
import { TextImageButton } from '../../lib/ui/buttons/TextImageButton';
import { BasicText } from '../../lib/ui/text/BasicText';
import { TextInput } from '../../lib/ui/text/TextInput';

//-----------------------------------------------------------------------------
export type SubscribeButtonPopupOptions = {};

// manifest
//-----------------------------------------------------------------------------
const manifest = {
    smallBubble: 'bubble.small.png',
    bubbleTail: 'bubble.tail.png',
    frame: 'dialogue.blue.fade.png',
    input: 'frame.input.png',
    blueButton: 'button.blue.png',
    bigBubble: 'bubble.large.png',
    hande: 'hand.png',
};

// support
//-----------------------------------------------------------------------------
export function pwaIsSelfInstalled(): boolean {
    // ios
    return (
        (window.navigator as any).standalone ||
        // android
        window.matchMedia('(display-mode: standalone)').matches ||
        window.matchMedia('(display-mode: fullscreen)').matches
    );
}

export function pwaIsSubscribed(): boolean {
    return Notification.permission === 'granted';
}

export function pwaSubscribePrompted(): boolean {
    return pwaIsSubscribed() || Notification.permission === 'denied';
}

export async function pwaInstall() {
    if (!pwaIsSelfInstalled()) {
        await firstTapPromise;
        if (app.platform.ios) {
            console.log('PWA: IOS install');
            await app.nav.open('addHomePopup1');
        } else {
            const web = InstantGame.platform as PlatformWeb;
            console.log('PWA: platform: ', web.platformID);
            if (await web.canCreateShortcutAsync()) {
                try {
                    web.createShortcutAsync()
                        .then(() => {
                            console.log('PWA: createShortcutAsync OK');
                            //waOpenInstalled();
                            ++app.busy.count;
                        })
                        .catch(() => {
                            console.log('PWA: createShortcutAsync FAIL');
                            app.nav.open('addHomePopup1', { direct: true });
                            --app.busy.count;
                        });
                } catch {}
            } else {
                console.log('PWA: canCreateShortcutAsync FAIL');
                //await app.nav.open('addHomePopup1');
            }
        }
    }
}

export async function pwaIsInstalled() {
    if (!app.platform.ios && !pwaIsSelfInstalled()) {
        try {
            const apps = (await (navigator as any).getInstalledRelatedApps()) as string[];
            console.log('PWA: getInstalledRelatedApps: ', apps.length);
            if (apps.length > 0) return true;
        } catch {}
    }
    console.log('PWA: getInstalledRelatedApps: FAIL');
    return false;
}

export async function pwaOpenInstalled() {
    const web = InstantGame.platform as PlatformWeb;
    let timer: ReturnType<typeof setInterval>;
    let installed = false;
    if (!pwaIsSelfInstalled()) {
        await firstTapPromise;
        console.log('PWA: pwaOpenInstalled: START');
        timer = setInterval(async () => {
            if ((await pwaIsInstalled()) && !installed) {
                installed = true;
                --app.busy.count;
                console.log('PWA: pwaOpenInstalled: INSTALLED');
                clearInterval(timer);
                app.nav.open('openHomePopup');
                // console.log('PWA: pwaOpenInstalled INSTALLED');
                // const oninput = async () => {
                //     window.removeEventListener('pointerdown', oninput);
                //     console.log('PWA: pwaOpenInstalled OPENING', await web.canCreateShortcutAsync());
                //     window.open(window.location.origin, '_blank');
                // };
                // window.addEventListener('pointerdown', oninput);
            }
        }, 250);

        //TODO: doesnt seem to fire
        console.log('PWA: appinstalled: REGISTER');
        window.addEventListener('appinstalled', () => {
            console.log('PWA: appinstalled: FIRED');
            //window.open(window.location.origin, '_blank');
        });
    }
}

/*
    Transparent overlay popup, used for tutorial pet name
*/
export class SubscribeButtonPopup extends LayoutScreen3 {
    // fields
    //-------------------------------------------------------------------------
    private _input: TextInput;
    private _subButton: TextImageButton;
    private _unsubButton: TextImageButton;
    private _sendButton: TextImageButton;
    private _installButton: TextImageButton;
    private _subStatus: BasicText;
    private _inputContainer: Container;
    private _topFrame: NineSlicePlane;

    // impl
    //-------------------------------------------------------------------------
    public preload() {
        return app.resource.loadAssets([...Object.values(manifest)]);
    }

    public async spawning(options: SubscribeButtonPopupOptions) {
        // spawn scene
        await this._spawn(options);
    }

    public async despawned() {
        this.empty();
        //this._input.stop();
    }

    public async despawning() {
        await this.animateOut();
    }

    public async spawned(): Promise<void> {
        //this._input.start();
        await this.animateIn();
    }

    public override resized(size: SizeType): void {
        super.resized(size);
        //uiAlignCenterX(size, this._inputContainer);

        uiAlignRight(size, this._subButton, -30);
        uiAlignRight(size, this._unsubButton, -30);
        uiAlignRight(size, this._subStatus, -200);
        uiAlignRight(size, this._sendButton, -30);

        //this._inputContainer.y = size.height - 300;
    }

    // private: handlers
    //-------------------------------------------------------------------------
    private async _onSubscribe() {
        const web = InstantGame.platform as PlatformWeb;

        console.log('subscribeBotAsync.I');
        try {
            await web.subscribeBotAsync();
        } catch (e) {
            console.log('subscribeBotAsync.E', e);
        }
        console.log('subscribeBotAsync.O');

        // register push sub event
        // web.setPushSubscriptionChangedListener((subscription: PushSubscription) => {
        //     // can be null
        //     if (subscription) {
        //         console.log('setPushSubscriptionChangedListener', subscription, subscription.toJSON());
        //         // forward to backend
        //         app.server.invoke
        //             .pwaSubscribe({ subscription: subscription.toJSON() as WebPushSubscription })
        //             .then(() => {
        //                 this._sendButton.visible = true;
        //             });
        //     }
        // });

        // request user permissions. should show a native popup.
        try {
            console.log('requestPermission.I');
            const result = await Notification.requestPermission();
            console.log('requestPermission.O', result);
        } catch (e) {
            console.log('requestPermission.E', e);
        }
    }

    private async _onUnsubscribe() {
        console.log('pwaUnsubscribe');
        app.server.invoke.pwaUnsubscribe();
    }

    private async _onSend() {
        // send notification to self
        app.server.invoke.pwaSend({
            uid: app.server.state.id,
            message: 'hello world!',
            url: window.location.href,
        });
    }

    private async _onInstall() {
        await pwaInstall();
        // const web = InstantGame.platform as PlatformWeb;

        // // android flow
        // if (await web.canCreateShortcutAsync()) {
        //     console.log('createShortcutAsync.I');
        //     try {
        //         // arge:feature is for analytics
        //         await web.createShortcutAsync();
        //     } catch (e) {
        //         console.log('createShortcutAsync.E', e);
        //     }
        //     console.log('createShortcutAsync.O');
        //     // ios/etc flow
        // } else {
        //     await app.nav.open('addHomePopup1');
        // }
    }

    private async _onStateInc() {
        await app.server.invoke.pwaStateInc();
    }

    // private: scene
    //-------------------------------------------------------------------------
    private async _spawn(options: SubscribeButtonPopupOptions) {
        const web = InstantGame.platform as PlatformWeb;

        const subButton = (this._subButton = new TextImageButton({
            text: 'SUBSCRIBE',
            image: manifest.blueButton,
            y: -4,
            slice: {
                width: 210,
                height: 69,
                left: 45,
                top: 0,
                right: 45,
                bottom: 0,
            },
            style: {
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowDistance: 3,
                dropShadowAlpha: 0.7,
                dropShadowBlur: 5,
                fill: '#fff',
                fontSize: 28,
                fontWeight: 'bold',
                lineJoin: 'round',
                strokeThickness: 2,
            },
        }));
        subButton.y = 30;
        subButton.onPress = this._onSubscribe.bind(this);

        const unsubButton = (this._unsubButton = new TextImageButton({
            text: 'UNSUB',
            image: manifest.blueButton,
            y: -4,
            slice: {
                width: 210,
                height: 69,
                left: 45,
                top: 0,
                right: 45,
                bottom: 0,
            },
            style: {
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowDistance: 3,
                dropShadowAlpha: 0.7,
                dropShadowBlur: 5,
                fill: '#fff',
                fontSize: 28,
                fontWeight: 'bold',
                lineJoin: 'round',
                strokeThickness: 2,
            },
        }));
        unsubButton.y = 90;
        unsubButton.onPress = this._onUnsubscribe.bind(this);

        const subStatus = (this._subStatus = new BasicText({
            style: {
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowDistance: 3,
                dropShadowAlpha: 0.7,
                dropShadowBlur: 5,
                fill: '#f80',
                fontSize: 20,
                fontWeight: 'bold',
                lineJoin: 'round',
                strokeThickness: 2,
            },
        }));
        subStatus.y = 160;

        const sendButton = (this._sendButton = new TextImageButton({
            text: 'SEND',
            image: manifest.blueButton,
            y: -4,
            slice: {
                width: 210,
                height: 69,
                left: 45,
                top: 0,
                right: 45,
                bottom: 0,
            },
            style: {
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowDistance: 3,
                dropShadowAlpha: 0.7,
                dropShadowBlur: 5,
                fill: '#fff',
                fontSize: 28,
                fontWeight: 'bold',
                lineJoin: 'round',
                strokeThickness: 2,
            },
        }));
        sendButton.y = 220;
        sendButton.onPress = this._onSend.bind(this);
        sendButton.visible = false;

        this.root.addChild(subButton, unsubButton, subStatus, sendButton);

        const installButton = (this._installButton = new TextImageButton({
            text: 'INSTALL',
            image: manifest.blueButton,
            y: -4,
            slice: {
                width: 210,
                height: 69,
                left: 45,
                top: 0,
                right: 45,
                bottom: 0,
            },
            style: {
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowDistance: 3,
                dropShadowAlpha: 0.7,
                dropShadowBlur: 5,
                fill: '#fff',
                fontSize: 28,
                fontWeight: 'bold',
                lineJoin: 'round',
                strokeThickness: 2,
            },
        }));
        installButton.y = 30;
        installButton.onPress = this._onInstall.bind(this);
        this.root.addChild(installButton);

        const installedStatus = new BasicText({
            style: {
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowDistance: 3,
                dropShadowAlpha: 0.7,
                dropShadowBlur: 5,
                fill: '#0f0',
                fontSize: 20,
                fontWeight: 'bold',
                lineJoin: 'round',
                strokeThickness: 2,
            },
        });
        installedStatus.x = 30;
        installedStatus.y = 100;
        this.root.addChild(installedStatus);

        const stateIncButton = new TextImageButton({
            text: 'STATE INC',
            image: manifest.blueButton,
            y: -4,
            slice: {
                width: 210,
                height: 69,
                left: 45,
                top: 0,
                right: 45,
                bottom: 0,
            },
            style: {
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowDistance: 3,
                dropShadowAlpha: 0.7,
                dropShadowBlur: 5,
                fill: '#fff',
                fontSize: 28,
                fontWeight: 'bold',
                lineJoin: 'round',
                strokeThickness: 2,
            },
        });
        stateIncButton.y = 150;
        stateIncButton.onPress = this._onStateInc.bind(this);
        this.root.addChild(stateIncButton);

        const stateStatus = new BasicText({
            style: {
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowDistance: 3,
                dropShadowAlpha: 0.7,
                dropShadowBlur: 5,
                fill: '#f08',
                fontSize: 20,
                fontWeight: 'bold',
                lineJoin: 'round',
                strokeThickness: 2,
            },
        });
        stateStatus.x = 50;
        stateStatus.y = 220;
        this.root.addChild(stateStatus);

        const url = new URL(location.href);
        const userId = url.searchParams.get('user') || '';
        const token = url.searchParams.get('token') || '';
        const loginStatus = new BasicText({
            text: `UID: ${userId}\nTOKEN: ${token}`,
            style: {
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowDistance: 3,
                dropShadowAlpha: 0.7,
                dropShadowBlur: 5,
                fill: '#0ff',
                fontSize: 16,
                fontWeight: 'bold',
                lineJoin: 'round',
                strokeThickness: 2,
            },
        });
        loginStatus.x = 10;
        loginStatus.y = 300;
        this.root.addChild(loginStatus);

        setInterval(() => {
            try {
                this._subStatus.text = `STATUS: ${Notification.permission}`;
            } catch {
                this._subStatus.text = `STATUS: N/A`;
            }
            try {
                sendButton.visible = Notification.permission === 'granted';
            } catch {}

            stateStatus.text = `STATE: ${app.server.state.pwaTest1}`;
            installedStatus.text = `INSTALLED: ${pwaIsSelfInstalled()}`;
        }, 500);
    }

    private async animateIn() {}

    private async animateOut() {}
}
