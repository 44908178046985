import { Container, Sprite } from 'pixi.js';

import app from '../../../index.entry';
import { SizeType } from '../../../lib/defs/types';
import { TouchInputComponent } from '../../../lib/pixi/components/TouchInputComponent';
import { uiAlignBottom, uiAlignCenter, uiAlignCenterX } from '../../../lib/pixi/uiTools';
import { tween } from '../../../lib/util/tweens';
import { pixiConfig } from '../../defs/config';
import { LayoutScreen3 } from '../../lib/screens/LayoutScreen3';
import { ImageButton } from '../../lib/ui/buttons/ImageButton';
import { BasicText } from '../../lib/ui/text/BasicText';
import { TextInput } from '../../lib/ui/text/TextInput';
import { pwaInstall } from './SubscribeButtonPopup';

//-----------------------------------------------------------------------------
export type AddHomePopup1Options = {
    direct: boolean;
};

// manifest
//-----------------------------------------------------------------------------
const manifest = {
    button: 'button-gray.png',
    close: 'close-x.png',
    install: 'button-install.png',
};

/*
    Transparent overlay popup, used for tutorial pet name
*/
export class AddHomePopup1 extends LayoutScreen3 {
    // fields
    //-------------------------------------------------------------------------
    private _input: TextInput;
    private _confirmButton: ImageButton;
    private _inputContainer: Container;
    private _topFrame: Sprite;

    // properties
    //-------------------------------------------------------------------------
    public get confirmButton() {
        return this._confirmButton;
    }

    // impl
    //-------------------------------------------------------------------------
    public preload() {
        return app.resource.loadAssets([...Object.values(manifest)]);
    }

    public async spawning(options: AddHomePopup1Options) {
        // spawn scene
        this._spawn(options);
    }

    public async despawned() {
        this.empty();
        this.root.removeChildren();
        //this._input.stop();
    }

    public async despawning() {
        await this.animateOut();
    }

    public async spawned(): Promise<void> {
        //this._input.start();
        await this.animateIn();
    }

    public override resized(size: SizeType): void {
        super.resized(size);
        uiAlignCenter(size, this._topFrame);
        uiAlignBottom(
            size,
            this._topFrame,
            -290 * Math.max(size.height / size.width / (pixiConfig.size.height / pixiConfig.size.width), 1),
        );
        //uiScaleToWidth(this._topFrame, size.width);
        uiAlignCenterX(size, this._topFrame);
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn(options: AddHomePopup1Options) {
        const topFrame = (this._topFrame = Sprite.from(manifest.install));
        this.root.addChild(topFrame);

        const header = topFrame.addChild(
            new BasicText({
                text: app.platform.ios ? '[pwaInstallIos]' : '[pwaInstallAndroid]',
                style: {
                    fill: '#fff',
                    fontSize: 34,
                    lineJoin: 'round',
                    fontWeight: 'bold',
                },
            }),
        );
        uiAlignCenter(topFrame, header);
        header.x -= 55;

        Object.assign(new TouchInputComponent(topFrame), {
            onTap: async () => {
                await app.nav.close('addHomePopup1');
                console.log('PWA: addHomePopup: OPEN');
                if (options?.direct) pwaInstall();
                else if (app.platform.ios) await app.nav.open('addHomePopup2');
                else await app.nav.open('addHomePopup2Android');
                // app.nav.close('openHomePopup');
                // console.log('PWA: OpenHomePopup: TAPPED');
                // window.open(window.location.origin, '_blank');
            },
        });

        // const topFrame = (this._topFrame = uiCreateQuad(0xffffff, 1, 750, 600));
        // this.root.addChild(topFrame);

        // const icon = topFrame.addChild(Sprite.from(manifest.icon));
        // icon.x = 46;
        // icon.y = 112;

        // const button = topFrame.addChild(
        //     new TextImageButton({
        //         text: '[pwaAdd]',
        //         image: manifest.button,
        //         slice: {
        //             width: 654,
        //             height: 129,
        //             left: 64,
        //             top: 64,
        //             right: 64,
        //             bottom: 64,
        //         },
        //         style: {
        //             fill: '#000',
        //             fontSize: 28,
        //             lineJoin: 'round',
        //         },
        //     }),
        // );
        // button.y = 286;
        // button.onPress = async () => {
        //     await app.nav.close('addHomePopup1');
        //     if (app.platform.ios) await app.nav.open('addHomePopup2');
        //     else await app.nav.open('addHomePopup2Android');
        // };
        // uiAlignCenterX(topFrame, button);

        // const msg = topFrame.addChild(
        //     new BasicText({
        //         text: '[pwaAdding]',
        //         style: {
        //             fill: '#000',
        //             fontSize: 30,
        //             lineJoin: 'round',
        //         },
        //     }),
        // );
        // msg.x = 222;
        // msg.y = 125;

        // const header = topFrame.addChild(
        //     new BasicText({
        //         text: '[pwaEnjoy]',
        //         style: {
        //             fill: '#000',
        //             fontSize: 38,
        //             lineJoin: 'round',
        //             fontWeight: 'bold',
        //         },
        //     }),
        // );
        // uiScaleToWidth(header, 580);
        // header.y = 41;
        // uiAlignCenterX(topFrame, header);

        // const close = topFrame.addChild(
        //     new ImageButton({
        //         image: manifest.close,
        //     }),
        // );
        // close.x = 666;
        // close.y = 37;
        // close.onPress = async () => {
        //     app.nav.close('addHomePopup1');
        // };
    }

    private async animateIn() {
        const y = this._topFrame.y;
        this._topFrame.y += 430;
        await this._topFrame.animate().add(this._topFrame, { y }, 0.5, tween.backOut(1.5));
    }

    private async animateOut() {
        await this._topFrame.animate().add(this._topFrame, { y: this._topFrame.y + 430 }, 0.25, tween.backIn(1.5));
    }
}
