import { Sprite, Texture } from 'pixi.js';

import app from '../../../index.entry';
import { ILoader } from '../../../plugins/nav/ILoader';
import { angleContain } from '../../../replicant/util/mathTools';
import { pixiConfig } from '../../defs/config';
import { LayoutScreen } from '../../lib/screens/LayoutScreen';

// settings
//-----------------------------------------------------------------------------
const spinRate = Math.PI / 2;

// constants
//-----------------------------------------------------------------------------
const manifest = {
    spinner: 'spinner.png',
};

/*
    nav: simple spinner overlay loader
*/
export class SpinnerLoader extends LayoutScreen implements ILoader {
    // fields
    //-------------------------------------------------------------------------
    private _spinner: Sprite;

    // init
    //-------------------------------------------------------------------------
    constructor() {
        super();
    }

    // impl
    //-------------------------------------------------------------------------
    public preload() {
        return app.resource.loadAssets([...Object.values(manifest)]);
    }

    public async init() {
        // spawn spinner
        this._spawn();
    }

    public step(dt: number) {
        // spin teh spinner!
        this._spinner.rotation = angleContain(this._spinner.rotation + dt * spinRate);
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn() {
        // spawn
        const spinner = (this._spinner = Sprite.from(manifest.spinner).props({
            anchor: { x: 0.5, y: 0.5 },
        }));

        // layout
        this.root.addContent({
            bg: {
                content: Sprite.from(Texture.WHITE).props({
                    tint: 0,
                    alpha: 0.5,
                    width: pixiConfig.size.width * 2,
                    height: pixiConfig.size.height * 2,
                }),
                styles: {
                    position: 'center',
                },
            },
            spinner: {
                content: spinner,
                styles: {
                    position: 'center',
                    paddingLeft: spinner.width,
                },
            },
        });
    }
}
