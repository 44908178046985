import {
    Application,
    gameResize,
    I18nPlugin,
    ResizePlugin,
    ResourcePlugin,
    SoundPlugin,
    StagePlugin,
    VisibilityPlugin,
} from '@play-co/astro';
import { PlatformWeb } from '@play-co/gcinstant';

import app from '../index.entry';
import { PerformanceAnalytics } from '../lib/performance/PerformanceAnalytics';
import { pixiSetScene } from '../lib/pixi/pixiTools';
import { firstTapPromise } from '../lib/util/events';
// @ts-ignore manifest.json does not exist before client build
import manifest from '../manifest.json';
import { InstantGame } from '../plugins/instantGames/InstantGame';
import { InstantGamePlugin } from '../plugins/instantGames/InstantGamesPlugin';
import { NavPlugin } from '../plugins/nav/NavPlugin';
import { ResourceExPlugin } from '../plugins/resourceEx/ResourceExPlugin';
import { BusyComponent } from './components/BusyComponent';
import { pixiConfig } from './defs/config';
import { NavLayer, navLoaders, navScreens } from './defs/nav';
import { languageFontMap } from './defs/text';
import { PetTutorialFlow } from './flows/tutorials/PetTutorialFlow';
import { getForcedLanguage, getUserPreferredLanguage } from './lib/util/device';
import { AnalyticsService } from './services/AnalyticsService';
import { CoreService } from './services/CoreService';
import { GameService } from './services/GameService';
import { InputService } from './services/InputService';
import { MusicService } from './services/MusicService';
import { PlatformService } from './services/PlatformService';
import { SettingsService } from './services/SettingsService';
import { SoundService } from './services/SoundService';
import { TrackingService } from './services/TrackingService';
import { CheatScreen } from './world/cheat/CheatScreen';
import {
    pwaInstall,
    pwaIsSelfInstalled,
    pwaOpenInstalled,
    pwaSubscribePrompted,
} from './world/pet/SubscribeButtonPopup';

/*
    application core
*/
export class App extends Application {
    // fields
    //-------------------------------------------------------------------------
    // plugins
    public i18nPlugin!: I18nPlugin;
    public instantGamePlugin!: InstantGamePlugin;
    public nav!: NavPlugin;
    public resizePlugin!: ResizePlugin;
    public resourcePlugin!: ResourcePlugin;
    public resource!: ResourceExPlugin;
    public soundPlugin!: SoundPlugin;
    public stage!: StagePlugin;
    public visibilityPlugin!: VisibilityPlugin;
    // services (app specific plugins)
    public core!: CoreService;
    public input!: InputService;
    public platform!: PlatformService;
    public game!: GameService;
    public analytics!: AnalyticsService;
    public music!: MusicService;
    public settings!: SettingsService;
    public sound!: SoundService;
    public tracking!: TrackingService;
    // components
    public busy: BusyComponent;
    // state
    public server: typeof InstantGame.replicant;

    // init
    //-------------------------------------------------------------------------
    constructor() {
        super(pixiConfig);
    }

    // impl
    //-------------------------------------------------------------------------
    public async run(): Promise<void> {
        // init plugins
        this._initPlugins();

        // init services
        this._initServices();

        // init application (await inits all plugins then services in order)
        await this.init();

        // create busy component
        this.busy = new BusyComponent();

        console.log(`Logged in as ${app.server.state.id}`);

        // Must trigger EntryFinal after PreLaunchCommand
        await InstantGame.sendEntryFinal();

        // post init start
        this._start();
    }

    // api
    //-------------------------------------------------------------------------
    //TODO: move next 3 into UiService
    // show an alert popup with an optional title
    public async showAlert(message: string, title = '[popupErrorGeneralTitle]') {
        // open error popup and wait on close
        await new Promise((resolve) =>
            this.nav.open('alertPopup', { title, message, onOk: resolve, onClose: resolve }),
        );

        // close
        await this.nav.close('alertPopup');
    }

    // show debug text
    public debug(...args: string[]) {
        (this.nav.get('cheatScreen') as CheatScreen)?.setDebug(args.join(','));
    }

    // private: start
    //-------------------------------------------------------------------------
    private async _start() {
        // const url = new URL(location.href);
        // url.searchParams.set('abc', '123');
        // history.replaceState(null, '', url.href);
        // open debug menu
        if (process.env.IS_DEVELOPMENT) {
            // TODO: should we only allow whitelist users to cheat menu?
            this.nav.open('cheatScreen');
            this.nav.open('subscribeButtonPopup');
        }

        //app.nav.open('addHomePopup2Android');
        // app.nav.open('addHomePopup1'); // install button
        // app.nav.open('addHomePopup2'); // install tutorial screen
        // app.nav.open('openHomePopup'); // open game button

        // Initialize performance analytics
        PerformanceAnalytics.init();

        // if in homescreen, request notifications, it not already prompted
        if (pwaIsSelfInstalled()) {
            if (!pwaSubscribePrompted()) this._pwaSub();
        } else {
            // else auto open homescreen app
            pwaOpenInstalled();
        }

        // if beginner tutorial complete
        if (app.server.state.tutorial.pets.complete) {
            // Open defaut scene
            app.nav.open('petScreen');
            pwaInstall();
        } else {
            // else run tutorial
            await new PetTutorialFlow().execute();
            // show optional pointer if we come from tutorial
            app.nav.open('petScreen', { showPointer: true });

            // show home nag
            pwaInstall();
        }
    }

    private async _pwaSub() {
        const web = InstantGame.platform as PlatformWeb;
        await firstTapPromise;

        console.log('subscribeBotAsync.I');
        try {
            await web.subscribeBotAsync();
        } catch (e) {
            console.log('subscribeBotAsync.E', e);
        }
        console.log('subscribeBotAsync.O');

        // request user permissions. should show a native popup.
        try {
            console.log('requestPermission.I');
            const result = await Notification.requestPermission();
            console.log('requestPermission.O', result);
            //trackPushRequest({ result: result === 'granted' ? 'accept' : 'deny' });
        } catch (e) {
            console.log('requestPermission.E', e);
        }
    }

    // private: init
    //-------------------------------------------------------------------------
    private _initPlugins(): void {
        // install stage plugin and register the root scene
        this.stage = this.add(StagePlugin, { name: 'stage' });
        pixiSetScene(this.stage.stage);

        // install resize plugin
        this.resizePlugin = this.add(ResizePlugin, {
            resizeFunction: gameResize(pixiConfig.size.width, pixiConfig.size.height),
        });

        // install resource plugins
        this.resourcePlugin = this.add(ResourcePlugin, {
            name: 'resource',
            manifest,
        });
        this.resource = this.add(ResourceExPlugin, {
            name: 'resourceEx',
        });

        // install instant games plugin
        this.instantGamePlugin = this.add(InstantGamePlugin);

        // add navigation plugin
        this.nav = this.add(NavPlugin, {
            layers: NavLayer.count,
            screens: navScreens,
            loaders: navLoaders,
            preload: this._getLaunchScene.bind(this),
        });

        // install sound plugin
        this.soundPlugin = this.add(SoundPlugin, {});

        // install visibility plugin
        this.visibilityPlugin = this.add(VisibilityPlugin);

        // install i18n (localization) plugin. needs to be down here or errors
        this.i18nPlugin = this.add(I18nPlugin, {
            //generatedFontLanguages: ['en','ja'], // this should match the `fontLanguages` in `fido-config.json`
            entryDefaults: languageFontMap,
            //language: 'ja',
            defaultManifestID: 'assets/i18n',
        });
    }

    private _initServices(): void {
        // add core service
        this.core = this.add(CoreService, {});

        // add input service
        this.input = this.add(InputService, {});

        // add analytics service
        this.analytics = this.add(AnalyticsService, {});

        const forcedLanguage = getForcedLanguage();
        const preferredLanguage = getUserPreferredLanguage();
        // add settings service
        this.settings = this.add(SettingsService, { forcedLanguage, preferredLanguage });

        // add music service
        this.music = this.add(MusicService, {});

        // add sound service
        this.sound = this.add(SoundService, {
            channels: 16,
        });

        // add platform service
        this.platform = this.add(PlatformService, {});

        // add game service
        this.game = this.add(GameService, {});

        // add tracking service
        this.tracking = this.add(TrackingService, {});
    }

    // private: support
    //-------------------------------------------------------------------------
    private _getLaunchScene(): string {
        return 'petScreen';
    }
}
