const PROD = 'https://openfairy.playco.games';
const DEV = 'https://concept-push-dev.cdn.gc-internal.net';
const ALPHA = 'https://concept-push-alpha.cdn.gc-internal.net';
const LOCAL = 'https://localhost:8052/';

export function getGameUrl() {
    let url = LOCAL; // No stage -> local dev.
    if (process.env.STAGE === 'prod') url = PROD;
    if (process.env.STAGE === 'dev') url = DEV;
    if (process.env.STAGE === 'alpha' || process.env.STAGE === 'bravo' || process.env.STAGE === 'charlie') url = ALPHA;

    return url;
}
